<script setup>
import { useRoute } from 'nuxt/app';
import { useHomeStore } from '~/stores/home.store';
import DownloadApp from './partials/download-app.vue';

const route = useRoute();
const homeStore = useHomeStore();
const showSplashScreen = ref(true);

onMounted(() => {
  if (homeStore.showSplashScreen == true) {
    const { $gsap: gsap, $Draggable: Draggable } = useNuxtApp();

    gsap.set('.char', {
      fill: "none",
      stroke: "#fff",
      opacity: 0
    })
    gsap.to('.char', {
      fill: "#fff",
      stroke: "none",
      opacity: 1,
      duration: 0.3,
      y: 30,
      ease: "power1",
      yoyo: true,
      repeat: -1,
      stagger: {
        amount: 1,
        each: 0.5,
        from: "end",
        axe: "y"
      }
    })

    setTimeout(() => {
      showSplashScreen.value = false;
      homeStore.showSplashScreen = false;
    }, 1400);
  } else {
    showSplashScreen.value = false;
  }
})
</script>

<style>
@import url("../../assets/scss/mobile/index.scss");
</style>

<template>
  <div class="fixed h-screen w-screen bg-indigo-900 z-[9999]"
    v-if="showSplashScreen && route.fullPath == localePath({ name: 'home' })">
    <svg xmlns="http://www.w3.org/2000/svg" width="407" height="267" fill="none"
      class="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/3">
      <g id="Group 499">
        <g id="logo" fill="none">
          <path class="char r2"
            d="M275.37 134.8c-.029-.021-.026-.063.006-.078.993-.461 5.042-1.814 5.457-7.223.422-5.508-5.298-6.39-5.384-6.403H258.848a.047.047 0 0 0-.047.047v24.451c0 .026.021.047.047.047h6.844a.047.047 0 0 0 .047-.047v-9.724c0-.026.02-.047.046-.047h1.341c.91 0 1.761.439 2.297 1.177 1.857 2.556 4.462 8.2 4.654 8.617.007.016.023.024.04.024h8.309a.048.048 0 0 0 .045-.063c-2.416-7.302-6.538-10.383-7.101-10.778Zm-5.031-3.886h-4.341a.047.047 0 0 1-.047-.047v-5.029c0-.026.021-.047.047-.047h4.76c1.571 0 2.793 1.503 2.317 3.155-.343 1.194-1.499 1.968-2.736 1.968Z" />
          <path class="char u"
            d="M245.188 145.395c1.402-.217 2.71-.629 3.947-1.398a9.556 9.556 0 0 0 2.218-1.891c.788-.919 1.393-1.983 1.828-3.112.726-1.885.345-4.042.349-6.01.003-1.837 0-3.674 0-5.511v-6.832h-7.203l-.013 14.941s-.002 4.642-4.183 4.683c-.079.005-.164.005-.247.008a2.253 2.253 0 0 0-.247.003c-4.181-.052-4.183-4.694-4.183-4.694l-.013-14.941h-7.203v6.832c0 1.837-.003 3.674 0 5.511.004 1.968-.377 4.125.349 6.01.435 1.129 1.04 2.193 1.828 3.112 2.944 3.433 7.866 3.842 12.083 3.379.233-.025.463-.055.69-.09Z" />
          <path class="char t" d="M203.438 121.096v5.763h7.752v18.782h7.523v-18.877h7.764v-5.668h-23.039Z" />
          <path class="char r1"
            d="M195.425 134.8a.044.044 0 0 1 .006-.078c.992-.461 5.042-1.814 5.457-7.223.421-5.508-5.298-6.39-5.384-6.403h-16.602a.047.047 0 0 0-.047.047v24.451c0 .026.021.047.047.047h6.845a.047.047 0 0 0 .046-.047v-9.724c0-.026.021-.047.047-.047h1.34a2.84 2.84 0 0 1 2.298 1.177c1.856 2.556 4.462 8.2 4.653 8.617a.043.043 0 0 0 .041.024h8.309a.047.047 0 0 0 .044-.063c-2.415-7.302-6.537-10.383-7.1-10.778Zm-5.032-3.886h-4.341a.047.047 0 0 1-.046-.047v-5.029c0-.026.021-.047.046-.047h4.761c1.571 0 2.793 1.503 2.317 3.155-.344 1.194-1.499 1.968-2.737 1.968Z" />
          <path class="char a"
            d="M166.873 121.095h-8.929l-9.101 24.546h7.763l1.521-4.173h8.844l1.177 4.173h7.667l-8.942-24.546Zm-7.229 14.087 2.685-8.964 3.121 8.964h-5.806Z" />
          <path class="char v"
            d="m142.92 120.883-5.598 17.501-5.367-17.501h-7.814l8.934 24.758h8.499l9.109-24.758h-7.763Z" />
        </g>
      </g>
    </svg>
  </div>

  <download-app v-if="homeStore.showDownloadApp"></download-app>
  <slot name="mobile-header"></slot>

  <main class="mb-32 relative">
    <slot></slot>
  </main>

  <slot name="mobile-footer"></slot>


  <template v-if="!$slots['mobile-footer']">
    <MobileFooter></MobileFooter>
  </template>
</template>
