<script setup>
import { useRouter, useRoute } from 'nuxt/app';
import { useHomeStore } from '~/stores/home.store';

const homeStore = useHomeStore();
const countries = await homeStore.fetchCountriesHasProperties();
const route = useRoute();
const router = useRouter()
const localePath = useLocalePath()

const route_name = ref(route.name.split('___')[0]);

const isAuthModalOpen = ref(false)

const authorize = () => {
  router.push({ path: localePath({ name: 'account.login' }) });
  isAuthModalOpen.value = false
}
</script>

<template>
  <footer class="fixed bottom-0 bg-white w-full z-50">
    <w-modal v-model="isAuthModalOpen" :transition="true" class="w-full" :ui="{ container: 'items-center' }">
      <div class="p-8 text-center flex flex-col gap-3">
        <h3 class="text-gray-900 text-2xl my-3">
          {{ $t("client.you_should_login_or_register_for_add_listing") }}
        </h3>
      </div>
      <w-button
        class="mx-auto text-lg text-white bg-indigo-900 p-3 rounded-md text-center border-0 m-5 block hover:bg-indigo-800"
        @click="authorize">
        {{ $t('client.login_register') }}
      </w-button>
    </w-modal>
    <div class="flex px-4 justify-between relative">
      <NuxtLink :to="localePath({ name: 'home' })"
        class="flex flex-col items-center justify-center p-3 inset-0 relative"
        :class="{ [route_name === 'home' ? 'active' : 'text-neutral-400']: true }">
        <i class="fa-solid fa-home text-2xl mb-1"></i>
        <span class="text-xs block">Home</span>
      </NuxtLink>
      <NuxtLink :to="localePath({ name: 'search' })"
        class="flex flex-col items-center justify-center p-3 inset-0 relative"
        :class="{ [route_name === 'search' ? 'active' : 'text-neutral-400']: true }">
        <i class="fa-solid fa-search text-2xl mb-1"></i>
        <span class="text-xs block">Search</span>
      </NuxtLink>

      <NuxtLink :to="localePath({ name: 'projects.index', params: { location: countries.find((c) => c._id == 228)?.slug } })"
        class="flex flex-col items-center justify-center p-3 inset-0 relative"
        :class="{ [route_name === 'projects.index' ? 'active' : 'text-neutral-400']: true }">
        <i class="fa-solid fa-city text-2xl mb-1"></i>
        <span class="text-xs block">Projects</span>
      </NuxtLink>
      <NuxtLink :to="localePath({ name: 'more' })"
        class="flex flex-col items-center justify-center p-3 inset-0 relative"
        :class="{ [route_name === 'more' ? 'active' : 'text-neutral-400']: true }">
        <i class="fa-solid fa-ellipsis text-2xl mb-1"></i>
        <span class="text-xs  block">More</span>
      </NuxtLink>
    </div>
  </footer>
</template>